import { useNavigate } from 'react-router-dom';
import ApiFetch from '../../services/api/fetch';
import { strings } from '../../constants/strings';
import { IResponse, THttpMethods } from '../../types/api/fetch';

export const useApiFetch = () => {
    const api = new ApiFetch();

    const navigate = useNavigate();

    /**
     * Перейти на страницу "Авторизация"
     */
    const goToAuthPage = () => {
        api.removeUserToken();
        navigate('/auth');
    };

    /**
     * Получить данные от сервера (описание api-методов хранятся по пути /src/services/api/connections.ts)
     * @param apiMethod - api-метод
     * @param httpMethod - http-метод ('get', 'post'), в нижнем регистре, т.к. использвуются в качестве ключа axios
     * @param useToken - требуется ли токен для api-метода
     * @param body - тело http-запроса
     * @param mainToken - использовать основной токен
     */
    const fetchData = async <T>(apiMethod: string, httpMethod: THttpMethods, useToken?: boolean, body?: any, mainToken?: boolean): Promise<IResponse<T>> => {
        const result = await api.fetchData<T>(apiMethod, httpMethod, useToken, body, mainToken);

        if (result.status === 401) {
            await goToAuthPage();

            return {
                ...result,
                message: result.message || strings.userTokenOutdateText,
            };
        }

        return result;
    };

    return {
        fetchData,
        removeUserToken: api.removeUserToken,
        saveUserToken: api.saveUserToken,
        getHeaders: api.getHeaders,
        downloadFile: api.downloadFile,
    };
};
